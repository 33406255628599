export default {
    flatConfDef: {
        tooltip: true,//tooltip
        twidth: 160,//表格宽度
        isSort: true,//是否排序
    },
    flatConfig: {
        staffQuit: {
            name: "##员工-离职申请+审批，原表：nanny_quit",
            formCol: 1,//表单每行显示几列
            fieldList: [
                {field: 'id', name: 'id', Table: false, find: false, Export: false},
                {field: 'is_del', name: '删除标识（0正常，基于id删除）', Table: false, find: false, Export: false},
                {field: 'create_time', name: '创建时间', Table: false, find: false, Export: false},
                {field: 'update_time', name: '更新时间', Table: false, find: false, Export: false},
                {field: 'del_time', name: '删除时间', Table: false, find: false, Export: false},
                {field: 'del_user_id', name: '删除人', Table: false, find: false, Export: false},
                {field: 'sys_tenant_code', name: '租户编号', Table: false, find: false, Export: false},
                {field: 'dep_dict', name: '部门', Table: true, find: true, Export: true},
                {field: 'manager_status', name: '经理审批', Table: true, find: true, Export: true},
                {field: 'status', name: '终审', Table: true, find: true, Export: true},
                {field: 'name', name: '姓名', Table: true, find: true, Export: true},
                {field: 'number', name: '工号', Table: true, find: true, Export: true},
                {field: 'post', name: '岗位', Table: true, find: true, Export: true},
                {field: 'entry_time', name: '进公司时间', Table: true, find: true, Export: true},
                {field: 'quit_time', name: '离职时间', Table: true, find: true, Export: true},
                {field: 'quit_reason', name: '离职原因', Table: true, find: true, Export: true},
                {field: 'finance', name: '财务', Table: true, find: true, Export: true},
                {field: 'area', name: '片区', Table: true, find: true, Export: true},
                {field: 'kfb', name: '客服部', Table: true, find: true, Export: true},
                {field: 'rsb', name: '人事部', Table: true, find: true, Export: true},
                {field: 'dep_opinion', name: '部门意见', Table: true, find: true, Export: true},
                {field: 'staff_id', name: '员工id，原=nanny_id', Table: true, find: true, Export: true},
                {field: 'reason', name: '离职原因', Table: true, find: true, Export: true},
                {field: 'result', name: '终审理由', Table: true, find: true, Export: true},
                {field: 'sys_user_id', name: '终审人ID', Table: true, find: true, Export: true},
                {field: 'manager_result', name: '经理审批理由', Table: true, find: true, Export: true},
                {field: 'manager_id', name: '经理审批ID', Table: true, find: true, Export: true},
                {field: 'files', name: '附件', Table: true, find: true, Export: true},
                {field: 'create_time', name: '创建时间', table: true, twidth: 120, find: true, type: "组件类型",},
            ]
        },
        sysDictItem: {
            name: "系统-系统字典-项",
            fieldList: [
                {field: 'id', name: 'null', Table: false, find: false, Export: false},
                {field: 'is_del', name: '删除标识（0正常，基于id删除）', Table: false, find: false, Export: false},
                {field: 'create_time', name: '创建时间', Table: false, find: false, Export: false},
                {field: 'update_time', name: '更新时间', Table: false, find: false, Export: false},
                {field: 'del_time', name: '删除时间', Table: false, find: false, Export: false},
                {field: 'del_user_id', name: '删除人', Table: false, find: false, Export: false},
                {field: 'dict_code', name: '字典编号', Table: true, find: true, Export: true},
                {field: 'pkey', name: '上级编号', Table: true, find: true, Export: true},

                {field: 'key', name: '编号', Table: true, find: true, Export: true},
                {field: 'value', name: '值', Table: true, find: true, Export: true},
                {field: 'icon', name: '图标', Table: true, find: true, Export: true},
                {field: 'status', name: '状态', Table: true, find: true, Export: true},
                {field: 'color', name: '颜色', Table: true, find: true, Export: true},
                {field: 'app_color', name: 'APP颜色', Table: true, find: true, Export: true},
                {field: 'tag_type', name: 'Tag', Table: true, find: true, Export: true},
                {field: 'name', name: '名称', Table: true, find: true, Export: true},
                {field: 'order_num', name: '顺序', Table: true, find: true, Export: true},
                {field: 'prop', name: 'JSON属性', Table: true, find: true, Export: true},
                {field: 'skill_json', name: '包含技能', Table: true, find: true, Export: true},
                {field: 'level_json', name: '包含等级', Table: true, find: true, Export: true},
                {field: 'content', name: '说明', Table: true, find: true, Export: true},
                {field: 'key_type', name: '编号类型', Table: true, find: true, Export: true},
                {field: "createTime", name: "创建时间", Table: true, find: true, export: true}
            ]
        },
        nxArea: {
            name: "行政区划",
            fieldList: [
                {field: "pid", name: "上级", Table: true, find: true, Export: true},
                {field: "name", name: "名称", Table: true, find: true, Export: true},
                {field: "id", name: "组织机构代码", Table: true, find: true, Export: true},
                {field: "status", name: "状态", Table: true, find: true, Export: true},
                {field: "is_sales", name: "销售区", Table: true, find: true, Export: true},
                {field: "pid_del", name: "上级删除", Table: true, find: true, Export: true},
                {field: "short_name", name: "简称", Table: false, find: true, Export: true},
                // {field: "short_name_full", name: "简称全称", Table: true, find: true, Export: true},

                {field: "full_name", name: "全称", Table: true, find: false, Export: true},
                {field: "province_name", name: "省级全称", Table: true, find: false, Export: true},
                {field: "city_name", name: "市级全称", Table: true, find: false, Export: true},
                {field: "county_name", name: "县级全称", Table: true, find: false, Export: true},

                {field: "remark", name: "备注", Table: true, find: true, Export: true},
                {field: "order_num", name: "顺序", Table: true, find: true, Export: true},
                {field: "source_id", name: "原始ID", Table: true, find: true, Export: true},
                {field: "create_time", name: "创建时间", Table: true, find: true, export: true}
            ]
        },
        goodsServerItem: {
            name: "##商品-服务项基本信息（树）",
            formCol: 1,//表单每行显示几列
            fieldList: [
                {field: 'id', name: 'id', Table: false, find: false, Export: false},
                {field: 'is_del', name: '删除标识（0正常，基于id删除）', Table: false, find: false, Export: false},
                {field: 'create_time', name: '创建时间', Table: false, find: false, Export: false},
                {field: 'update_time', name: '更新时间', Table: false, find: false, Export: false},
                {field: 'del_time', name: '删除时间', Table: false, find: false, Export: false},
                {field: 'del_user_id', name: '删除人', Table: false, find: false, Export: false},
                {field: 'sys_tenant_code', name: '租户编号', Table: false, find: false, Export: false},
                {field: 'pcode', name: '上级编号', Table: true, find: true, Export: true},
                {field: 'custom_price', name: '显示价格', Table: true, find: true, Export: true},
                {field: 'failure_type', name: '有效期类型', Table: true, find: true, Export: true},
                {field: 'failure_date', name: '有效期长度', Table: true, find: true, Export: true},

                {field: 'is_item', name: '是否是目录', Table: false, find: true, Export: false},
                {field: 'name', name: '名称', Table: true, find: true, Export: true},
                {field: 'code', name: '编号', Table: true, find: true, Export: true},
                {field: 'status', name: '状态', Table: true, find: true, Export: true},
                {field: 'remark', name: '备注', Table: true, find: true, Export: true},
                {field: 'show_type', name: '显示风格', Table: true, find: true, Export: true},
                {field: 'price', name: '单价', Table: true, find: true, Export: true},
                {field: 'gs_price', name: '工时费', Table: true, find: true, Export: true},
                {field: 'unit', name: '单位', Table: true, find: true, Export: true},
                {field: 'serve_num', name: '服务次数', Table: true, find: true, Export: true},
                {field: 'lower_desc', name: '下级选项说明', Table: true, find: true, Export: true},
                {field: 'replenish', name: '补充说明（赠送）', Table: false, find: false, Export: true},
                {field: 'area_count', name: '商品项类型', Table: true, find: true, Export: true},
                {field: 'min_area', name: '最小服务面积（㎡）', Table: true, find: true, Export: true},
                {field: 'alert_text', name: '下单提示信息', Table: true, find: true, Export: true},
                {field: 'server_pay', name: '是否服务完付款', Table: true, find: true, Export: true},
                {field: 'buy_limit', name: '购买次数限制', Table: true, find: true, Export: true},
                {field: 'order_limit', name: '下单次数限制', Table: true, find: true, Export: true},
                {field: 'is_bargain', name: '是否参与砍价', table: true, find: true, Export: true},
                {field: 'bargain_num', name: '砍价次数', table: true, find: true, Export: true},
                {field: 'bargain_min_money', name: '砍价下限金额', table: true, find: true, Export: true},
                {field: 'bargain_max_money', name: '砍价上限金额', table: true, find: true, Export: true},
                {field: 'bargain_time', name: '砍价付款时限(分钟)', table: true, find: true, Export: true},

                // {field: 'show_full_name', name: '显示全称', Table: true, find: true, Export: true},

                {field: 'give_name', name: '赠送名称', Table: true, find: true, Export: true},
                {field: 'full_name', name: '完整名称', Table: true, find: true, Export: true},
                {field: 'item_list', name: '服务项', Table: true, find: true, Export: true},
                {field: 'order_num', name: '顺序', Table: true, find: true, Export: true},
                {field: 'item_area_json', name: '区域价格', Table: true, find: true, Export: true},
                {field: 'img', name: '图片', Table: true, find: true, Export: true},
                {field: 'create_time', name: '创建时间', table: true, twidth: 120, find: true, type: "组件类型",},
            ]
        },
        orderServe: {
            name: "##订单-服务记录",
            formCol: 1,//表单每行显示几列
            fieldList: [
                {field: 'id', name: 'id', Table: false, find: false, Export: false},
                {field: 'is_del', name: '删除标识（0正常，基于id删除）', Table: false, find: false, Export: false},
                {field: 'create_time', name: '创建时间', Table: false, find: false, Export: false},
                {field: 'update_time', name: '更新时间', Table: false, find: false, Export: false},
                {field: 'del_time', name: '删除时间', Table: false, find: false, Export: false},
                {field: 'del_user_id', name: '删除人', Table: false, find: false, Export: false},
                {field: 'sys_tenant_code', name: '租户编号', Table: false, find: false, Export: false},
                {field: 'order_info_id', name: '订单', Table: true, find: true, Export: true},
                {field: 'c_name', name: '客户姓名', Table: true, find: true, Export: true},
                {field: 'c_phone', name: '客户电话', Table: true, find: true, Export: true},
                {field: 'c_area', name: '客户地址', Table: true, find: true, Export: true},
                {field: 'server_time', name: '服务时间', Table: true, find: true, Export: true},
                {field: 'goods_server_item_code', name: '商品项编号', Table: true, find: true, Export: true},
                {field: 'goods_server_item_json', name: '商品项对象', Table: true, find: true, Export: true},
                {field: 'server_item_name', name: '服务项', Table: true, find: true, Export: true},
                {field: 'server_num', name: '服务次数（次）', Table: true, find: true, Export: true},
                {field: 'no_code', name: '服务单号', Table: true, find: true, Export: true},
                {field: 'server_money', name: '服务金额（元）', Table: true, find: true, Export: true},
                {field: 'pay_mondy', name: '已收款金额（元）', Table: true, find: true, Export: true},
                {field: 'staff_info_id', name: '服务员工', Table: true, find: true, Export: true},
                {field: 'nx_area_id', name: '订单服务区域', Table: true, find: true, Export: true},
                {field: 'firm_manager_id', name: '负责经理', Table: true, find: true, Export: true},
                {field: 'firm_area_id', name: '负责区域', Table: true, find: true, Export: true},
                {field: 'firm_id', name: '加盟商', Table: true, find: true, Export: true},
                {field: 'jzstaff_info_ids', name: '服务员工', Table: true, find: true, Export: true},
                {field: 'validity_period_time', name: '合同到期时间', Table: true, find: true, Export: true},

                {field: 'create_time', name: '创建时间', table: true, twidth: 120, find: true, type: "组件类型",},
            ]
        },
    },
    vueFindFiledName: function (code) {
        var tableName = this.tableName;
        var flatConfigMap = this.flatConfig[tableName];
        if (flatConfigMap) {
            var fieldList = flatConfigMap.fieldList;
            var fieldMap = flatConfigMap.fieldMap;
            if (fieldMap == null) {
                fieldMap = this.listJsonToMap(fieldList, "field");
                this.$set(this.flatConfig[tableName], "fieldMap", fieldMap);
            }
            var item = fieldMap[code];
            // console.log(fieldMap)
            if (item) {
                var txt = item.findName ? item.findName : item.name;
                // console.log("txt",txt)
                // this.fieldNameMap[code]=txt;
                return txt;
            } else {
                console.error("flatConfig." + tableName + ".fieldMap." + code + "不存在", fieldList);
            }
        } else {
            console.error("flatConfig." + tableName + "不存在", this.flatConfig);
        }
        return "null";
    },
    vueFindFiledInit: function (code) {
        var map = {};
        var tableName = this.tableName;
        var flatConfigMap = this.flatConfig[tableName];
        if (flatConfigMap) {
            var fieldList = flatConfigMap.fieldList;
            var fieldMap = flatConfigMap.fieldMap;
            if (fieldMap == null) {
                fieldMap = this.listJsonToMap(fieldList, "field");
                this.$set(this.flatConfig[tableName], "fieldMap", fieldMap);
            }
            console.log(fieldMap);
            var item = fieldMap[code];
            if (item) {
                return item.findName ? item.findName : item.name;
            } else {
                console.error("flatConfig." + tableName + ".fieldMap." + code + "不存在", fieldList);
            }
        } else {
            console.error("flatConfig." + tableName + "不存在", this.flatConfig);
        }
        return "null";
    },
    /**
     * 1.页面表格显示列配置与导出配置查询
     * 2.比较
     */
    configCompare: function () {
        //console.log(this.tableName)
        var config = this.flatConfig[this.tableName];
        if (!config) {
            return;
        }
        if (config.type == 'new') {
            for (var key in config) {
                this.$set(this.config, key, config[key]);
            }
            return;
        }
        var fieldList = config.fieldList;
        this.table_config = config.table_config || {
            cols: [], wheres: {}, table: {}
        }
        ;
        var searchList = config.searchList;
        var formCol = config.formCol;
        this.$set(this.config, 'tableSelection', config.tableSelection);
        this.$set(this.config, 'formCol', formCol);
        var fieldListMap = this.listJsonToMap(fieldList, "field");
        var tableList = config.tableList;
        var tableFieldList = [];
        var vueConfigMap = this.getSession("vueConfigMap");
        var status = false;
        if (tableList) {
            tableList.split(",").forEach(c => {
                var o = fieldListMap[c];
                if (o)
                    tableFieldList.push(o)
            })
        } else {
            tableFieldList = fieldList
        }
        var formFieldList = [];
        var colNum = 0;
        fieldList.forEach((o, i) => {
                if (o.show != false && o.find != false) {
                    if (!o.colspan) {
                        o.colspan = formCol;
                    }
                    if (o.colspan > formCol) {
                        o.colspan = formCol;
                    }
                    colNum += o.colspan;
                    o.colNum = colNum;
                    formFieldList.push(o)
                }
            }
        );

        // console.log(formFieldList)
        this.$set(this.config, 'formFieldList', formFieldList);
        this.$set(this.config, 'searchList', searchList);
        if (vueConfigMap) {
            var vueConfig = vueConfigMap[this.config.vueKey];
            // console.log(vueConfigMap[this.config.vueKey])
            if (vueConfig && vueConfig.config) {
                var configV = vueConfig.config;
                var tableFieldListV = this.listJsonToMap(configV.fieldListTable, "field");
                var newFieldListExport = this.listJsonToMap(configV.fieldListExport, "field");
                var newFieldListTable2 = this.copyObj(this.fieldListCompa(tableFieldList, tableFieldListV));
                this.$set(this.config, 'fieldListTable', newFieldListTable2);
                this.$set(this.config, 'fieldListExport', this.copyObj(this.fieldListCompa(fieldList, newFieldListExport)));
                status = true;


            }

        }
        //
        if (status == false) {
            // console.log("tableFieldList", tableFieldList)
            // this.config.fieldListTable=[]
            // this.config.fieldListTable.push({})
            // console.log("this.config.fieldListTable",this.config.fieldListTable)
            // console.log("config", this.config)
            if (!this.config.fieldListTable) {
                this.config.fieldListTable = []
            }
            tableFieldList.forEach(o => {
                this.config.fieldListTable.push(o)

            });
            this.config.showTable = true
            // this.$set(this.config, 'fieldListTable', tableFieldList);
            // this.$set(this.config, 'fieldListExport', this.copyObj(tableFieldList));
        }

    },
    fieldListCompa: function (fieldList, newFieldList) {
        var fieldListTable = [];
        fieldList.forEach(item => {
            let newFieldObj = newFieldList[item.field];
            if (newFieldObj) {
                item.$i$ = newFieldObj.$i$;
                item.show = newFieldObj.show;
            }
            fieldListTable.push(item);
        });
        return this.bubblingSort(fieldListTable, "$i$");
    },
}
